<template>
  <div v-if="store.user?.first_name" class="flex sm:justify-between sm:flex-row flex-col items-start z-50">
    <div class="flex flex-col gap-2">
      <div class="flex items-center cursor-pointer" @click="$router.push('/')">
        <DashboardLogo />
      </div>
    <p class="N900 font-semibold self-center">Welcome back {{ firstAndLastname }} 👋🏻</p>
    </div>
  
  <div class="flex justify-end content-center N700 z-10" @mouseleave="removeDropdowns">
    <div class="flex gap-4">
      <div v-if="permitedApps?.length > 0" class="self-center cursor-pointer flex gap-2 bg-[#D8DAE5] p-2 rounded-lg" @click="showAppsDropdown = !showAppsDropdown">
        <img src="../assets/menu-icons/apps.svg" class="appsIcon" alt="menu icons svg" />
        <p class="text-[#101840]">Apps</p>
      </div>
      <div class="flex self-center bg-[#D8DAE5] p-2 rounded-lg cursor-pointer" v-if="viewDomainPage">
        <TooltipComponent text="Access rights">
        <a @click.prevent="$router.push('/domains')">
          <SettingsSVG />
        </a>
      </TooltipComponent>
      </div>
      <div
        class="flex gap-4 cursor-pointer"
        @click="showProfiledropdown = !showProfiledropdown"
      >
        <ProfileImageComponent
          :imgSrc="profilePicture"
          :initials="getUserNameInitials(firstAndLastname)"
          :border="''"
        />
        <p class="self-center N900 username">{{ username }}</p>
        <img src="@/assets/arrow-down.svg" class="cursor-pointer h-fit self-center" alt="arrow down svg" />
      </div>
      <ProfileDropdown v-if="showProfiledropdown" />
      <AppsNavigation v-if="showAppsDropdown" />
    </div>
  </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "@/store/index";
import ProfileDropdown from "./ProfileDropdown.vue";
import AppsNavigation from "./AppsNavigation.vue";
import ProfileImageComponent from "@/ui-kit/components/Avatar/ProfileImageComponent.vue";
import { getUserNameInitials } from "@/helpers/utils";
import DashboardLogo from "../assets/DashboardLogo.vue";
import SettingsSVG from "../assets/SettingsSVG.vue";
import TooltipComponent from "@/ui-kit/Tooltip/TooltipComponent.vue";


const store = useStore();
const permitedApps = computed(()=>(store.user?.apps));
const username = computed(()=>(store.user.first_name + ""));
const firstAndLastname = computed(()=>(store.user.first_name + " " + store.user.last_name));

const profilePicture = ref(store.user.profile_image);
store.$subscribe((_, state)=> {
  profilePicture.value = state.user.profile_image;
})
const showProfiledropdown = ref<boolean>(false);
const showAppsDropdown = ref<boolean>(false);
const viewDomainPage = computed(() => {
  return store.user.roles?.includes("super admin")
})
const removeDropdowns = () => {
  showAppsDropdown.value = false;
  showProfiledropdown.value = false;
};
</script>
<style>
@media (max-width: 420px) {
  .profile-img {
    border-radius: 50%;
  }

  .username {
    display: none;
  }
}
</style>
