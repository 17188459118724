import moment from "moment";

/**
 * Encrypts the given ID and returns the encoded path.
 *
 * @param id The ID to be encrypted.
 * @returns The encoded path.
 */
const key = "snipersnevermisstheirtarget";
export const encodeUrlPath = (id: string): string => {
  const path=`${key}${id}`;
  return encodeURIComponent(btoa(path));
};

/**
 * Decrypts the encoded URL and returns the decrypted value.
 *
 * @param {string} encodedUrl - The URL encoded string to be decrypted.
 * @return {string} The decrypted URL value.d
 */
export const decodeUrlPath = (encodedUrl: string| string[]):string => {
  const path=atob(decodeURIComponent(encodedUrl as string));
  const id=path.split("snipersnevermisstheirtarget")[1];
  return id;
};

export const formatTime = (seconds: number):string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;
};

export const convertMinutesToHours = (duration: number) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  const seconds = 0;
  const time = moment()
    .hour(hours)
    .minute(minutes)
    .second(seconds)
    .format("HH:mm:ss");
  return {
    hours,
    minutes,
    seconds,
    time,
  };
};

  export function deepClone<T>(source: T): T {
    if (source === null || typeof source !== "object") {
      return source;
    }
  
    if (Array.isArray(source)) {
      return source.map((item) => deepClone(item)) as unknown as T;
    }
  
    const clonedObj = {} as T;
    for (const key in source) {
      if (Object.hasOwn(source, key)) {
        clonedObj[key] = deepClone(source[key]);
      }
    }
  
    return clonedObj;
  }

  export const getUserNameInitials = (name: string): string => {
    let words = name?.trim().split(" ");
    words = words?.length > 2 ? words.slice(0, 2) : words;
    return words?.map((word) => word[0]).join("") || "";
  };