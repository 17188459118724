import { UserType } from "@/apps/authentication/types/AuthTypes";
import { EmployeeOrganisationType } from "@/interfaces/employee_types";
import { defineStore } from "pinia";


export const useStore = defineStore({
  id: "login_data",
  state: () => ({
    email: "",
    id: 0,
    manager: 0,
    user: {} as UserType,
    profilePicture: "",
    onboardingPending: false as boolean,
    userInfo: {} as UserType,
    organisation: {} as EmployeeOrganisationType,
    loginHint: "",
  }),
  getters: {},
  actions: {
    setEmail(email: string) {
      this.email = email;
    },
    setId(id: number) {
      this.id = id;
    },
    setManager(id: number) {
      this.manager = id;
    },
    setUser(data: UserType) {
      this.user = data;
    },
    setProfilePicture(url: string) {
      this.profilePicture = url;
    },
    
    setUserInfo(data: UserType) {
      this.userInfo = data;
    },
    setLoginHint(data: string) {
      this.loginHint = data;
    },
  },
  persist: true,
});
