import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageNotFound from "../apps/PageNotFound.vue";
import { isLoggedIn, logout } from "../services/auth";

const env = process.env;

const route: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../apps/authentication/views/mainIndexPage.vue"
      ),
  },
  {
    path: "/sso-login",
    name: "SSO",
    component: () =>
      window.location.href = `${env.VUE_APP_SSO}/login`,
      beforeEnter: (_to, _from, next) => {
        if (_to.query["redirect-to"]) {
          window.location.href = `${env.VUE_APP_SSO}/login?redirect-to=${_to.query["redirect-to"]}`;	
        }else{
          window.location.href = `${env.VUE_APP_SSO}/login`;
        }
      }
  },
  {
    path: "/logout",
    name: "logout",
    alias: "/logout",
    component: () => "",
    meta: { auth: false },
    beforeEnter: (_to, _from, next) => {
      logout();
      next("/login");
    },
  },
  {
    path: "/",
    alias: "/home",
    name: "home",
    meta: { auth: true },
    component: () =>
    import(
      /* webpackChunkName: "dashboard" */ "../apps/homepage/views/IndexDashboard.vue"
    ),
  },
  {
    path: "/create-domain",
    name: "CreateDomain",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "create-domain" */ "../apps/authentication/components/CreateDomain.vue"
      ),
  },
  {
    path: "/domains",
    name: "Domains",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "domains" */ "../apps/authentication/components/DomainLists.vue"
      ),
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { auth: true },
  },

  /** End of Auth routes */
];

export const routes = route;

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  if (to.meta.auth && to.query["app-token"]) {
      next();
  }
  else if (to.meta.auth && !isLoggedIn()){
    next("/login");
  }
  else {
    next();
  }
});

export default router;
